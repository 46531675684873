




































































































































































































import Component, { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import Speaker from '@/models/graphql/Speaker';
import ToastActionParams from '@/utils/types/ToastActionParams';
import MyProfileInputText from '@/components/MyProfileInputText.vue';
import TextEditorComponent from '@/components/TextEditorComponent.vue';
import { Prop, Watch } from 'vue-property-decorator';
import ToastActionType from '@/utils/enums/ToastActionType';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import SocialNetworkDetector from 'social-network-detector';
import UrlType from '@/utils/enums/UrlType';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';
import { Data } from '@/utils/types/WidgetData';

const toastStore = namespace('ToastStore');
const speakerStore = namespace('SpeakerStore');

@Component({
  data(): object {
    return {
      speaker: {
        uid: '',
        firstName: '',
        lastName: '',
        jobTitle: '',
        employerName: '',
        suffix: '',
        prefix: '',
        bio: '',
        homepageUrl: '',
        twitterUrl: '',
        facebookUrl: '',
        instagramUrl: '',
        pinterestUrl: '',
      },
    };
  },
  components: {
    FontAwesomeComponent,
    TextEditorComponent,
    MyProfileInputText,
  },
})
/* eslint-disable no-underscore-dangle */
export default class SpeakerProfileInfoWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  @speakerStore.Action
  private updateSpeaker!: (payload: Partial<Speaker>) => Promise<Speaker | undefined>;

  @Prop({
    required: false,
    default: () => [],
  })
  private readonly data!: Data[];

  private urlType = UrlType;

  private previousDescription = '';

  private inputChanged = false;

  private dataNotSaved = false;

  private currentSelfContext = '';

  private editorConfigs = {
    readOnly: false,
    theme: 'snow',
    placeholder: '',
    formats: [
      'bold',
      'italic',
      'underline',
      'list',
    ],
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', { list: 'ordered' }, { list: 'bullet' }],
      ],
      clipboard: {
        matchVisual: false,
      },
    },
  };

  // eslint-disable-next-line class-methods-use-this
  addHttps(url: string): string {
    if (url && (!/^http?:\/\//i.test(url) && !/^https?:\/\//i.test(url))) {
      return `https://${url}`;
    }
    return url;
  }

  created(): void {
    if (this.authUser && this.authUser.speakers.length > 0) {
      this.setDataConfig([{
        alias: this.storeName,
        operation: 'speaker',
        gqlDefinition: buildQueryDefinition({
          filter: {
            type: GqlEntityFilterType.SPEAKER_FILTER,
            value: {
              uid: this.authUser.speakers[0].uid,
            },
          },
        }),
        fragmentName: 'speakerBasicInfoFragment',
      }]);
      this.setData();
    }
  }

  @Watch('isReadyToDisplay')
  setData(): void {
    if (this.isReadyToDisplay && this.data && this.data.length > 0) {
      Object.assign(this.authUser.speakers[0], this.data[0]);
      this.$data.speaker.uid = this.authUser.speakers[0].uid;
      this.$data.speaker.firstName = this.authUser.speakers[0].firstName || '';
      this.$data.speaker.lastName = this.authUser.speakers[0].lastName || '';
      this.$data.speaker.jobTitle = this.authUser.speakers[0].jobTitle || '';
      this.$data.speaker.employerName = this.authUser.speakers[0].employerName || '';
      this.$data.speaker.suffix = this.authUser.speakers[0].suffix || '';
      this.$data.speaker.prefix = this.authUser.speakers[0].prefix || '';
      this.$data.speaker.bio = this.authUser.speakers[0].bio;
      this.$data.speaker.homepageUrl = this.authUser.speakers[0].homepageUrl;
      this.$data.speaker.facebookUrl = this.authUser.speakers[0].facebookUrl || '';
      this.$data.speaker.twitterUrl = this.authUser.speakers[0].twitterUrl || '';
      this.$data.speaker.instagramUrl = this.authUser.speakers[0].instagramUrl || '';
      this.$data.speaker.pinterestUrl = this.authUser.speakers[0].pinterestUrl || '';
    }
  }

  private initDataNotSaved(): void {
    this.dataNotSaved = true;
    this.inputChanged = true;
  }

  @Watch('speaker.bio')
  private setDescription(): void {
    this.previousDescription = this.$data.speaker?.bio?.toString() || '';
  }

  private onDescriptionChange(data: { root: { innerHTML: string } }): void {
    if (data.root.innerHTML !== this.previousDescription && this.$data.speaker) {
      this.updateSpeaker({
        uid: this.$data.speaker.uid,
        bio: data.root.innerHTML,
      });
      if (this.authUser.speakers) {
        this.authUser.speakers[0].bio = data.root.innerHTML;
      }
      this.previousDescription = data.root.innerHTML;
      this.showToast(ToastActionType.UPDATE_SPEAKER_INFORMATION);
    }
  }

  private onPrefixChange(): void {
    if (this.$data.speaker && this.authUser.speakers
        && this.authUser.speakers[0].prefix !== this.$data.speaker.prefix) {
      this.updateSpeaker({
        uid: this.$data.speaker.uid,
        prefix: this.$data.speaker.prefix,
      });
      if (this.authUser.speakers) {
        this.authUser.speakers[0].prefix = this.$data.speaker.prefix;
      }
      this.showToast(ToastActionType.UPDATE_SPEAKER_INFORMATION);
    }
  }

  private onSuffixChange(): void {
    if (this.$data.speaker && this.authUser.speakers
        && this.authUser.speakers[0].suffix !== this.$data.speaker.suffix) {
      this.updateSpeaker({
        uid: this.$data.speaker.uid,
        suffix: this.$data.speaker.suffix,
      });
      if (this.authUser.speakers) {
        this.authUser.speakers[0].suffix = this.$data.speaker.suffix;
      }
      this.showToast(ToastActionType.UPDATE_SPEAKER_INFORMATION);
    }
  }

  private onLastNameChange(): void {
    if (this.$data.speaker && this.authUser.speakers && this.$data.speaker.lastName !== ''
        && this.authUser.speakers[0].lastName !== this.$data.speaker.lastName) {
      this.updateSpeaker({
        uid: this.$data.speaker.uid,
        lastName: this.$data.speaker.lastName,
      });
      if (this.authUser.speakers) {
        this.authUser.speakers[0].lastName = this.$data.speaker.lastName;
      }
      this.showToast(ToastActionType.UPDATE_SPEAKER_INFORMATION);
    }
  }

  private onFirstNameChange(): void {
    if (this.$data.speaker && this.authUser.speakers && this.$data.speaker.firstName !== ''
        && this.authUser.speakers[0].firstName !== this.$data.speaker.firstName) {
      this.updateSpeaker({
        uid: this.$data.speaker.uid,
        firstName: this.$data.speaker.firstName,
      });
      if (this.authUser.speakers) {
        this.authUser.speakers[0].firstName = this.$data.speaker.firstName;
      }
      this.showToast(ToastActionType.UPDATE_SPEAKER_INFORMATION);
    }
  }

  private onJobTitleChange(): void {
    if (this.$data.speaker && this.authUser.speakers && this.$data.speaker.jobTitle !== ''
        && this.authUser.speakers[0].jobTitle !== this.$data.speaker.jobTitle) {
      this.updateSpeaker({
        uid: this.$data.speaker.uid,
        jobTitle: this.$data.speaker.jobTitle,
      });
      if (this.authUser.speakers) {
        this.authUser.speakers[0].jobTitle = this.$data.speaker.jobTitle;
      }
      this.showToast(ToastActionType.UPDATE_SPEAKER_INFORMATION);
    }
  }

  private onEmployerNameChange(): void {
    if (this.$data.speaker && this.authUser.speakers && this.$data.speaker.employerName !== ''
        && this.authUser.speakers[0].employerName !== this.$data.speaker.employerName) {
      this.updateSpeaker({
        uid: this.$data.speaker.uid,
        employerName: this.$data.speaker.employerName,
      });
      if (this.authUser.speakers) {
        this.authUser.speakers[0].employerName = this.$data.speaker.employerName;
      }
      this.showToast(ToastActionType.UPDATE_SPEAKER_INFORMATION);
    }
  }

  private onUrlChange(urlType: UrlType): void {
    let result = '';
    let url = '';
    let attributes = {};
    if (this.authUser.speakers && this.authUser.speakers[0]) {
      switch (urlType) {
        case UrlType.WEBSITE:
          if (this.authUser.speakers[0].homepageUrl !== this.$data.speaker.homepageUrl) {
            result = this.$data.speaker.homepageUrl;
            attributes = {
              uid: this.$data.speaker.uid,
              homepageUrl: result,
            };
            this.authUser.speakers[0].homepageUrl = result;
          }
          break;
        case UrlType.FACEBOOK:
          if (this.authUser.speakers[0].facebookUrl !== this.$data.speaker.facebookUrl) {
            url = this.addHttps(this.$data.speaker.facebookUrl);
            if (this.getUrlType(url) === UrlType.FACEBOOK) {
              result = url;
            }
            attributes = {
              uid: this.$data.speaker.uid,
              facebookUrl: result,
            };
            this.authUser.speakers[0].facebookUrl = url;
          }
          break;
        case UrlType.INSTAGRAM:
          if (this.authUser.speakers[0].instagramUrl !== this.$data.speaker.instagramUrl) {
            url = this.addHttps(this.$data.speaker.instagramUrl);
            if (this.getUrlType(url) === UrlType.INSTAGRAM) {
              result = url;
            }
            attributes = {
              uid: this.$data.speaker.uid,
              instagramUrl: result,
            };
            if (this.authUser.speakers) {
              this.authUser.speakers[0].instagramUrl = url;
            }
          }
          break;
        case UrlType.TWITTER:
          if (this.authUser.speakers[0].twitterUrl !== this.$data.speaker.twitterUrl) {
            url = this.addHttps(this.$data.speaker.twitterUrl);
            if (this.getUrlType(url) === UrlType.TWITTER) {
              result = url;
            }
            attributes = {
              uid: this.$data.speaker.uid,
              twitterUrl: result,
            };
            if (this.authUser.speakers) {
              this.authUser.speakers[0].twitterUrl = url;
            }
          }
          break;
        case UrlType.PINTEREST:
          if (this.authUser.speakers[0].pinterestUrl !== this.$data.speaker.pinterestUrl) {
            url = this.addHttps(this.$data.speaker.pinterestUrl);
            if (this.getUrlType(url) === UrlType.PINTEREST) {
              result = url;
            }
            attributes = {
              uid: this.$data.speaker.uid,
              pinterestUrl: result,
            };
            if (this.authUser.speakers) {
              this.authUser.speakers[0].pinterestUrl = url;
            }
          }
          break;
        default:
          break;
      }
      if (result) {
        this.updateSpeaker(attributes);
        this.showToast(ToastActionType.UPDATE_SPEAKER_INFORMATION);
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private getUrlType(url: string): string {
    const detectedType = SocialNetworkDetector.detect(url);
    if (detectedType) {
      return detectedType;
    }
    return '';
  }

  private showToast(type: ToastActionType): void {
    this.addNewAction({ type });
  }
}
